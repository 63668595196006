import React from "react"

const Footer = () => (
  <>
    <div className="summery container-lg">
      <h1>
        Join <span>GLOWAPP</span> today and
        <br />
        take your salon experience to the <span>next level.</span>
      </h1>
      <p>Download</p>
      <div className="download">
        <a
          href="https://play.google.com/store/apps/details?id=com.garlicbed.glowapp"
          target="_blank"
          itemProp="sameAs"
        >
          <img
            src="/img/playstore.svg"
            alt="download glow app on playstore"
            className="store"
          />
        </a>
        <a
          href="https://apps.apple.com/app/glowapp-where-you-glow-up/id1639030942"
          target="_blank"
          itemProp="sameAs"
        >
          <img
            src="/img/appstore.svg"
            alt="download glow app on appstore"
            className="store"
          />
        </a>
      </div>
    </div>

    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <ul>
              <li>
                <a href="/terms-of-use">Terms of Use</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <p>
                  Find us on
                  <a
                    href="https://www.facebook.com/GlowApp-103240265691587/"
                    itemProp="sameAs"
                  >
                    <i className="fa-brands fa-facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com/glowapplk/"
                    itemProp="sameAs"
                  >
                    <i className="fa-brands fa-instagram" />
                  </a>
                </p>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>
                <p>Contact us</p>
              </li>
              <li>
                <p>support@glowapp.info</p>
              </li>
              <li>
                <a href="tel:+94765664663" itemProp="telephone">
                  <p>+94 765 664 663</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>
                <p>
                  Made in
                  <img
                    src="/img/lk.svg"
                    alt="Sri Lanka Flag SVG"
                    className="flag"
                  />
                  with
                  <i className="fa-solid fa-heart small" />
                </p>
              </li>
              <li>
                <p>Brought you by, </p>
              </li>

              <li className="garlicbed">
                <a href="https://www.garlicbed.com">
                  <img src="/img/garlicbed.svg" alt="garlic bed" />
                </a>
                <br />
                <a href="https://www.garlicbed.com">www.garlicbed.com</a>
                <p>© 2023 Garlic Bed (PVT) LTD. All Rights Reserved.</p>
                <p>Reg no: PV00258748 | D-U-N-S Number: 561798649</p>
              </li>

              <li>
                <p>
                  Image by
                  <a
                    className="photo-credit"
                    href="https://www.freepik.com/free-photo/interested-girl-stylish-purple-fur-coat-looking-camera-holding-sparkle-sunglasses_10979533.htm"
                  >
                    lookstudio
                  </a>
                  on
                  <a className="photo-credit" href="https://www.freepik.com">
                    Freepik
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
