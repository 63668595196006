import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/Layout"
import Navbar from "../components/Navbar.component"
import Footer from "../components/Footer.component"

type OwnProps = {
  data: {
    markdownRemark: {
      frontmatter: {
        date: string
        title: string
      }
      html: string
    }
  }
}

const RegulationsLayout = ({ data }: OwnProps) => {
  const source = { __html: data.markdownRemark.html }
  return (
    <Layout>
      <div>
        <Navbar />
        <div className="terms-of-use-bg">
          <div className="container terms-of-use-container">
            <h2>{data.markdownRemark.frontmatter.title}</h2>
            <div dangerouslySetInnerHTML={source}></div>
            <p id="last-update-policy">
              {data.markdownRemark.frontmatter.date}
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetMarkDownRegulationsFromFile($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`

export default RegulationsLayout
