import { ClientType } from "../data/types"

export const loginClient = async (
  userId: string,
  phone: string,
  name: string,
  email: string
) => {
  let client: ClientType | null = null

  // try {
  //   const clientDoc: DocumentSnapshot<any> = await ClientAPI.getClientById(
  //     userId
  //   )

  //   if (clientDoc.exists()) {
  //     client = { ...clientDoc.data(), email, id: clientDoc.id }

  //     if (client) {
  //       const privateClient = await ClientAPI.getPrivateClient(userId)
  //       client.ipgCustomerId = privateClient?.get("ipgCustomerId") || undefined
  //     }
  //   } else {
  //     client = await ClientAPI.createClientWeb({
  //       phone,
  //       name,
  //       email,
  //       source: "web",
  //       id: userId,
  //     })
  //   }
  //   return client
  // } catch (error) {
  //   throw error
  // }
}

export const signOut = () => {}
