import React, { useState } from "react"
import { navigate } from "@reach/router"
import { webAppUrl } from "../data/config"
import { ClientType } from "../data/types"
import { signOut } from "../util/auth.lib"

type OwnProps = {
  bg?: "primary" | "transparent" | "light-gradient"
  cartLength?: number
  client?: ClientType | null
  onClickSignOut?: () => void
}

const Navbar = ({ bg, cartLength = 0, client, onClickSignOut }: OwnProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("")

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (searchQuery.length > 0) {
      // window.location.replace(`${webAppUrl}/vendors?q=${searchQuery}`)
    }
  }

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const onClickCart = () => navigate("/marketplace/cart")

  const onClickSearch = () => {
    // window.location.replace(`${webAppUrl}/vendors`)
  }

  const onSignout = () => {
    signOut()
    onClickSignOut?.()
  }

  const renderCart = () => {
    if (cartLength) {
      const label = cartLength > 9 ? "9+" : cartLength
      return (
        <button className="nav-icon" onClick={onClickCart}>
          <i className="fa-solid fa-cart-shopping"></i>
          <label>{label}</label>
        </button>
      )
    }

    return null
  }

  const renderClient = () => {
    if (client) {
      return (
        <div className="dropdown">
          <button
            className="ms-2 nav-icon dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {client?.profilePicture ? (
              <img src={client.profilePicture} />
            ) : (
              <i className="fa-regular fa-circle-user"></i>
            )}
          </button>

          <ul
            className="dropdown-menu dropdown-menu-end shadow"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <a className="dropdown-item" href="/marketplace/orders">
                My orders
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="/marketplace/cart">
                My cart
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={onSignout}>
                Sign out
              </a>
            </li>
          </ul>
        </div>
      )
    }
    return null
  }

  return (
    <nav className={`navbar ${bg} `}>
      <div className="container-lg">
        <a className="navbar-brand" href="/">
          <img
            src="/img/appiconv2.webp"
            alt="glowapp-appicon"
            itemProp="logo"
            id="glowapp-logo"
          />
          YOU ARE BEAUTIFUL!
        </a>
        <div className="search">
          <a className="register-salon" href={webAppUrl}>
            Register my salon
          </a>
          {/* <form onSubmit={onSubmit}>
            <input
              placeholder="Search salon"
              name="salon"
              value={searchQuery}
              onChange={onChangeText}
            />

            <button className="search-btn" onClick={onClickSearch}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>

          <button className="mobile-search" onClick={onClickSearch}>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button> */}
          {renderClient()}
          {renderCart()}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
